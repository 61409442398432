@import url('https://rsms.me/inter/inter.css');

* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-y: hidden;  
}
#root {
  top: 0;
}

canvas + div::-webkit-scrollbar {
  display: none;
}
.logo {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo-img {
  min-width: 375px;
  width: 50%;
}

.logo > div {
  position: absolute;
  right: 0;
  top: 0;
}
.footer {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 10px;
  position: absolute;
  bottom: 0;
  height: 56px;
}
.footer > div {
  z-index: 99;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
}
.container {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  z-index: 100;
  padding: 10px;
}
.footer:hover {
  cursor: pointer;
  opacity: 1;
  height: 76px;  
  transition: 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.footer a > img {
  position: relative;
  height: 32px;
}


body {
  font-family: 'Inter';
  background: #fdfdfd;
}

a {
  color: black;
}

a {
  pointer-events: all;
  color: black;
  text-decoration: none;
}

svg {
  fill: black;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

canvas {
  opacity: 0;
  touch-action: none;
  animation: fade-in 5s ease 1s forwards;
}
